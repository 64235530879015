import paronApi from '@/utils/paron-api'
export default {
    getAllTransactions() {
        return paronApi.get('transaction/all')
    },

    getTransactionById(id) {
        return paronApi.get(`transaction/${id}`)
    },

    createTransaction(data) {
        return paronApi.post('transaction/new', data)
    },

    editTransactionById(id, data) {
        return paronApi.post(`transaction/${id}/edit`, data)
    },

    deleteTransactionById(id) {
        return paronApi.delete(`transaction/${id}`)
    },
    getTransactionsByFilter(page, filter, sortOption, order) {
        var path = page ? `transaction/filtered?page=${page}&per_page=10&` : 'transaction/filtered?'
        if (sortOption) {
            path += `sortOption=${sortOption}&order=${order}`
        }
        return paronApi.post(path, filter)
    }

}
